html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section,
summary {
  display: block; }

a {
  background-color: transparent;
  cursor: pointer; }

a:active,
a:hover {
  outline: 0; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto; }

* {
  box-sizing: border-box; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
  margin: 0;
  padding: 0; }

svg, i {
  display: block; }

h1, h2, h3, h4, h5 {
  font-family: "U8 Black", Arial, sans-serif;
  font-weight: normal; }

.desktop-contacts--list a:after, .signoff-bar:after, .signoff-contacts--list a:after, .explore-npuk--lists:after, .latest-news--item:after, .latest-news--content:after, .article-list--item:after, .article-intro:after, .quicklink-contacts--list a:after, .std-content:after, .downloads-list a:after {
  content: "";
  display: table;
  clear: both; }

.explore-npuk--header p, .explore-npuk--header i, .content-section--header i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%); }

.img-slider--controls, .partner--logo img, .supporter img, .donations-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); }

.main-logo img, .footer-nav--logo img, .publication--img img, .std-content img, .img-module img,
.img-gallery-slide img, .team-member--img img, .donations-modal--logo img {
  display: block;
  width: 100%;
  height: auto; }

.donate-bar, .signoff-bar {
  background: #f29204;
  /* Old browsers */
  background: -moz-linear-gradient(left, #f29204 0%, #ffe043 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #f29204 0%, #ffe043 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #f29204 0%, #ffe043 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f29204', endColorstr='#ffe043',GradientType=1 );
  /* IE6-9 */ }

.mobile-contacts--list, .desktop-contacts--list, .main-nav--list, .footer-nav--list, .signoff-contacts--list, .explore-npuk--list, .support-ctas--list, .social-links--list, .breadcrumbs--list, .article-list, .highlighted-list, .downloads-list, .sub-nav--list {
  display: block;
  list-style-type: none; }

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before, .slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  padding: 0; }

.slick-prev {
  left: -6rem;
  transform: rotate(180deg); }

.slick-next {
  right: -6rem; }

.slick-dots {
  margin: 3rem 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    display: inline-block;
    margin: 0 1rem; }
    .slick-dots li.slick-active button {
      background-color: #f29204; }
  .slick-dots button {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 5px;
    font-size: 0;
    line-height: 0;
    padding: 0; }

html {
  font-size: 57.5%; }

input[type=text], input[type=email], input[type=tel], input[type=password], input[type=submit], input[type=button], button, textarea {
  -webkit-appearance: none;
  border-radius: 0; }

body, input, select, option, button, textarea {
  -webkit-font-smoothing: antialiased; }

body {
  font-family: "U8 Medium", Arial, sans-serif;
  background: #fff;
  color: #333333; }

a[href^=tel] {
  color: inherit;
  text-decoration: none; }

i, svg {
  display: block; }

svg {
  width: 0;
  height: 0; }

.error-msg {
  padding: 6rem 0; }

.cc_logo {
  display: none !important; }

.cc_container {
  padding: 1.5rem !important;
  font-family: "U8 Black", Arial, sans-serif !important;
  background: #333333 !important; }

.cc_container a, .cc_container a:visited {
  color: #f29204 !important; }

.cc_container .cc_btn, .cc_container .cc_btn:visited {
  background-color: #ffe043 !important;
  color: #333333 !important; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .container:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 1200px) {
    .container {
      width: 1180px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .container-fluid:after {
    content: "";
    display: table;
    clear: both; }

.row {
  margin-left: -20px;
  margin-right: -20px; }
  .row:after {
    content: "";
    display: table;
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.3333333333%; }

.col-xs-2 {
  width: 16.6666666667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.3333333333%; }

.col-xs-5 {
  width: 41.6666666667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.3333333333%; }

.col-xs-8 {
  width: 66.6666666667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.3333333333%; }

.col-xs-11 {
  width: 91.6666666667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.3333333333%; }

.col-xs-pull-2 {
  right: 16.6666666667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.3333333333%; }

.col-xs-pull-5 {
  right: 41.6666666667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.3333333333%; }

.col-xs-pull-8 {
  right: 66.6666666667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.3333333333%; }

.col-xs-pull-11 {
  right: 91.6666666667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.3333333333%; }

.col-xs-push-2 {
  left: 16.6666666667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.3333333333%; }

.col-xs-push-5 {
  left: 41.6666666667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.3333333333%; }

.col-xs-push-8 {
  left: 66.6666666667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.3333333333%; }

.col-xs-push-11 {
  left: 91.6666666667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.3333333333%; }

.col-xs-offset-2 {
  margin-left: 16.6666666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.3333333333%; }

.col-xs-offset-5 {
  margin-left: 41.6666666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.3333333333%; }

.col-xs-offset-8 {
  margin-left: 66.6666666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.3333333333%; }

.col-xs-offset-11 {
  margin-left: 91.6666666667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.3333333333%; }
  .col-sm-2 {
    width: 16.6666666667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3333333333%; }
  .col-sm-5 {
    width: 41.6666666667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3333333333%; }
  .col-sm-8 {
    width: 66.6666666667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3333333333%; }
  .col-sm-11 {
    width: 91.6666666667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.3333333333%; }
  .col-sm-pull-2 {
    right: 16.6666666667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.3333333333%; }
  .col-sm-pull-5 {
    right: 41.6666666667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.3333333333%; }
  .col-sm-pull-8 {
    right: 66.6666666667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.3333333333%; }
  .col-sm-pull-11 {
    right: 91.6666666667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.3333333333%; }
  .col-sm-push-2 {
    left: 16.6666666667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.3333333333%; }
  .col-sm-push-5 {
    left: 41.6666666667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.3333333333%; }
  .col-sm-push-8 {
    left: 66.6666666667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.3333333333%; }
  .col-sm-push-11 {
    left: 91.6666666667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%; }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%; }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%; }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%; }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.3333333333%; }
  .col-md-2 {
    width: 16.6666666667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3333333333%; }
  .col-md-5 {
    width: 41.6666666667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3333333333%; }
  .col-md-8 {
    width: 66.6666666667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3333333333%; }
  .col-md-11 {
    width: 91.6666666667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.3333333333%; }
  .col-md-pull-2 {
    right: 16.6666666667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.3333333333%; }
  .col-md-pull-5 {
    right: 41.6666666667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.3333333333%; }
  .col-md-pull-8 {
    right: 66.6666666667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.3333333333%; }
  .col-md-pull-11 {
    right: 91.6666666667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.3333333333%; }
  .col-md-push-2 {
    left: 16.6666666667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.3333333333%; }
  .col-md-push-5 {
    left: 41.6666666667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.3333333333%; }
  .col-md-push-8 {
    left: 66.6666666667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.3333333333%; }
  .col-md-push-11 {
    left: 91.6666666667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.3333333333%; }
  .col-md-offset-2 {
    margin-left: 16.6666666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.3333333333%; }
  .col-md-offset-5 {
    margin-left: 41.6666666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.3333333333%; }
  .col-md-offset-8 {
    margin-left: 66.6666666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.3333333333%; }
  .col-md-offset-11 {
    margin-left: 91.6666666667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.3333333333%; }
  .col-lg-2 {
    width: 16.6666666667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3333333333%; }
  .col-lg-5 {
    width: 41.6666666667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3333333333%; }
  .col-lg-8 {
    width: 66.6666666667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3333333333%; }
  .col-lg-11 {
    width: 91.6666666667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.3333333333%; }
  .col-lg-pull-2 {
    right: 16.6666666667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.3333333333%; }
  .col-lg-pull-5 {
    right: 41.6666666667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.3333333333%; }
  .col-lg-pull-8 {
    right: 66.6666666667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.3333333333%; }
  .col-lg-pull-11 {
    right: 91.6666666667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.3333333333%; }
  .col-lg-push-2 {
    left: 16.6666666667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.3333333333%; }
  .col-lg-push-5 {
    left: 41.6666666667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.3333333333%; }
  .col-lg-push-8 {
    left: 66.6666666667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.3333333333%; }
  .col-lg-push-11 {
    left: 91.6666666667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

.row.ng {
  margin-right: 0;
  margin-left: 0; }
  .row.ng > [class^="col-"],
  .row.ng > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0; }

@font-face {
  font-family: 'U8 Black';
  src: url("fonts/U8-Black-web.eot");
  /* IE9 Compat Modes */
  src: url("fonts/U8-Black-web.woff") format("woff"); }

@font-face {
  font-family: 'U8 Medium';
  src: url("fonts/U8-Medium-web.eot");
  /* IE9 Compat Modes */
  src: url("fonts/U8-Medium-web.woff") format("woff"); }

@font-face {
  font-family: 'U8 Light';
  src: url("fonts/U8-Light-web.eot");
  /* IE9 Compat Modes */
  src: url("fonts/U8-Light-web.woff") format("woff"); }

.section-header {
  margin: 0 0 3rem;
  text-align: center; }
  .section-header h2 {
    font-family: "U8 Medium", Arial, sans-serif;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 992px) {
      .section-header h2 {
        font-size: 21px;
        font-size: 2.1rem; } }

.view-all {
  padding: 3rem 0;
  text-align: center; }
  @media (min-width: 1200px) {
    .view-all {
      padding: 6rem 0; } }
  .view-all a {
    display: inline-block;
    margin: 1rem;
    height: 5rem;
    padding: 0 0 0 1.5rem;
    background-color: #f7f6f2;
    border-radius: 5px;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #333333;
    line-height: 5rem;
    font-size: 16px;
    font-size: 1.6rem;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); }
    .view-all a i {
      margin: 0 0 0 0.5rem;
      float: right;
      width: 5rem;
      height: 5rem;
      padding: 1.2rem; }
      .view-all a i svg {
        width: 2.6rem;
        height: 2.6rem;
        fill: #333333; }
    @media (min-width: 992px) {
      .view-all a:hover {
        background-color: #f2f1ea; } }
    .view-all a i {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
      transform-origin: 50%; }

.pg-content {
  padding: 3rem 0; }
  .pg-content.light-grey {
    background-color: #f7f6f2; }
  @media (min-width: 1200px) {
    .pg-content {
      padding: 6rem 0; } }

.donate-bar {
  height: 5rem; }

.donate-bar--btn {
  float: left;
  height: 5rem;
  padding: 0 0 0 1.5rem;
  background-color: #333333;
  border-radius: 0;
  font-family: "U8 Black", Arial, sans-serif;
  text-decoration: none;
  color: #fff;
  line-height: 5rem;
  font-size: 16px;
  font-size: 1.6rem; }
  .donate-bar--btn i {
    margin: 0 0 0 0.5rem;
    float: right;
    width: 5rem;
    height: 5rem;
    padding: 1.2rem; }
    .donate-bar--btn i svg {
      width: 2.6rem;
      height: 2.6rem;
      fill: #fff; }
  @media (min-width: 992px) {
    .donate-bar--btn:hover {
      background-color: #2d2d2d; } }
  .donate-bar--btn i {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }

.mobile-contacts {
  float: right; }
  @media (min-width: 768px) {
    .mobile-contacts {
      display: none; } }

.mobile-contacts--list li {
  float: left; }

.mobile-contacts--list a {
  display: block;
  width: 5rem;
  height: 5rem; }

.mobile-contacts--list i {
  width: 5rem;
  height: 5rem;
  padding: 1.5rem; }
  .mobile-contacts--list i svg {
    width: 2rem;
    height: 2rem;
    fill: #333333; }

.site-search--outer {
  float: right; }

.toggle--site-search {
  display: block;
  width: 5rem;
  height: 5rem;
  font-family: "U8 Black", Arial, sans-serif;
  color: #333333;
  text-decoration: none; }
  @media (min-width: 768px) {
    .toggle--site-search {
      width: auto;
      padding: 0 0 0 1rem; } }
  @media (min-width: 992px) {
    .toggle--site-search:hover {
      background-color: rgba(255, 255, 255, 0.25); } }
  .toggle--site-search span {
    display: none; }
    @media (min-width: 768px) {
      .toggle--site-search span {
        display: block;
        height: 5rem;
        float: left;
        line-height: 5rem;
        font-size: 18px;
        font-size: 1.8rem; } }
  .toggle--site-search i {
    width: 5rem;
    height: 5rem;
    padding: 1.5rem; }
    .toggle--site-search i svg {
      width: 2rem;
      height: 2rem;
      fill: #333333; }
    @media (min-width: 768px) {
      .toggle--site-search i {
        float: right; } }
    .toggle--site-search i:last-child {
      display: none; }
  .toggle--site-search.open {
    color: #fff;
    background-color: #f29204; }
    @media (min-width: 992px) {
      .toggle--site-search.open:hover {
        background-color: #e58a04; } }
    .toggle--site-search.open i {
      display: none; }
      .toggle--site-search.open i:last-child {
        display: block; }
    .toggle--site-search.open svg {
      fill: #fff; }

.site-search {
  position: relative;
  display: none;
  height: 5rem;
  background-color: #fff;
  transition: all .5s; }
  .site-search i {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: #333333;
    width: 5rem;
    height: 5rem;
    padding: 1.5rem; }
    .site-search i svg {
      width: 2rem;
      height: 2rem;
      fill: #fff; }

.site-search--field {
  display: block;
  width: 100%;
  height: 5rem;
  padding: 0 6rem 0 1rem;
  border: none;
  font-size: 18px;
  font-size: 1.8rem; }

.site-search--submit {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 5rem;
  height: 5rem;
  border: none;
  background-color: transparent; }
  .site-search--submit:hover + .site-search i {
    background-color: #000; }

.masthead {
  position: relative;
  padding: 3rem 0 0; }
  @media (min-width: 992px) {
    .masthead {
      padding: 3rem 0; } }

.main-logo {
  display: block;
  width: 25%;
  margin: 0 auto 3rem; }
  @media (min-width: 768px) {
    .main-logo {
      width: 75%;
      margin: 0 0 3rem; } }
  @media (min-width: 992px) {
    .main-logo {
      margin: 0; } }

.desktop-contacts--outer {
  display: none; }
  @media (min-width: 768px) {
    .desktop-contacts--outer {
      display: block; } }

.desktop-contacts {
  margin: 1rem 0 0;
  float: right; }
  @media (min-width: 992px) {
    .desktop-contacts {
      margin: 6.5rem 0 0; } }
  @media (min-width: 1200px) {
    .desktop-contacts {
      margin: 7rem 0 0; } }
  .desktop-contacts h2 {
    margin: 0 0 1rem;
    color: #333333;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 992px) {
      .desktop-contacts h2 {
        margin: 0;
        float: left; } }
    @media (min-width: 1200px) {
      .desktop-contacts h2 {
        font-size: 21px;
        font-size: 2.1rem; } }

@media (min-width: 992px) {
  .desktop-contacts--list {
    float: left; } }

.desktop-contacts--list li {
  margin: 0 0 1rem;
  font-family: "U8 Black", Arial, sans-serif; }
  @media (min-width: 992px) {
    .desktop-contacts--list li {
      margin: 0 0 0 2rem;
      float: left; } }

.desktop-contacts--list a {
  color: #f29204;
  text-decoration: none;
  font-size: 18px;
  font-size: 1.8rem; }
  @media (min-width: 1200px) {
    .desktop-contacts--list a {
      font-size: 21px;
      font-size: 2.1rem; } }
  @media (min-width: 992px) {
    .desktop-contacts--list a:hover {
      color: #e58a04; }
      .desktop-contacts--list a:hover svg {
        fill: #e58a04; } }

.desktop-contacts--list i {
  margin: 0 1rem 0 0;
  float: left;
  width: 2rem;
  height: 2rem;
  padding: 0; }
  .desktop-contacts--list i svg {
    width: 2rem;
    height: 2rem;
    fill: #f29204; }
  @media (min-width: 1200px) {
    .desktop-contacts--list i {
      width: 2.4rem;
      height: 2.4rem;
      padding: 0; }
      .desktop-contacts--list i svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: #f29204; } }

.main-nav--outer {
  position: relative; }
  @media (min-width: 992px) {
    .main-nav--outer {
      position: absolute;
      top: 100%;
      z-index: 10;
      width: 100%;
      padding: 0 20px; } }

.toggle--main-nav {
  display: block;
  height: 5rem;
  padding: 0 0 0 1.5rem;
  background-color: #f7f6f2;
  border-radius: 0;
  font-family: "U8 Black", Arial, sans-serif;
  text-decoration: none;
  color: #333333;
  line-height: 5rem;
  font-size: 16px;
  font-size: 1.6rem; }
  .toggle--main-nav i {
    margin: 0 0 0 0.5rem;
    float: right;
    width: 5rem;
    height: 5rem;
    padding: 1.2rem; }
    .toggle--main-nav i svg {
      width: 2.6rem;
      height: 2.6rem;
      fill: #333333; }
  @media (min-width: 992px) {
    .toggle--main-nav:hover {
      background-color: #f2f1ea; } }
  .toggle--main-nav i {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }
  .toggle--main-nav.open {
    background-color: #e58a04;
    color: #fff; }
    .toggle--main-nav.open i {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
      transform-origin: 50%; }
    .toggle--main-nav.open svg {
      fill: #fff; }
  @media (min-width: 768px) {
    .toggle--main-nav {
      display: none; } }

.main-nav {
  display: none;
  position: absolute;
  top: 5rem;
  z-index: 1000000;
  width: 100%;
  background-color: #f29204; }
  @media (min-width: 768px) {
    .main-nav {
      position: static;
      display: block !important;
      background-color: #f7f6f2; } }
  @media (min-width: 992px) {
    .main-nav {
      max-width: 1140px;
      margin: 0 auto;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); } }

@media (min-width: 768px) {
  .main-nav--list {
    display: flex;
    flex-direction: row;
    width: 100%; } }

@media (min-width: 768px) {
  .main-nav--list li {
    flex-grow: 1;
    flex-basis: 1; } }

.main-nav--list a {
  display: block;
  height: 100%;
  padding: 1.5rem 1rem;
  font-family: "U8 Black", Arial, sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 768px) {
    .main-nav--list a {
      padding: 2.5rem 1rem;
      text-align: center;
      color: #333333; } }
  @media (min-width: 1200px) {
    .main-nav--list a {
      font-size: 18px;
      font-size: 1.8rem; } }
  @media (min-width: 992px) {
    .main-nav--list a:hover {
      background-color: #f2f1ea; } }

@media (min-width: 768px) {
  .main-nav--list .current_page_item a,
  .main-nav--list .current-page-ancestor a {
    background-color: #333333;
    color: #fff; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-nav--list {
    display: table;
    width: 100%; }
  .main-nav--list li {
    display: table-cell; } }

.pg-footer {
  padding: 3rem 0;
  background-color: #333333; }
  @media (min-width: 1200px) {
    .pg-footer {
      padding: 6rem 0; } }

.footer-nav--outer {
  margin: 0 0 3rem;
  text-align: center; }

.footer-nav--logo {
  display: inline-block;
  width: 25%;
  margin: 0 0 3rem; }
  @media (min-width: 768px) {
    .footer-nav--logo {
      vertical-align: middle;
      width: 15rem;
      margin: 0 2rem 0 0; } }

@media (min-width: 768px) {
  .footer-nav {
    display: inline-block;
    vertical-align: middle; } }

.footer-nav--list {
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .footer-nav--list {
      text-align: left; } }
  .footer-nav--list li {
    display: inline-block;
    margin: 1rem; }
    @media (min-width: 768px) {
      .footer-nav--list li {
        margin: 0 1rem; } }
  .footer-nav--list a {
    font-family: "U8 Black", Arial, sans-serif;
    color: #fff;
    text-decoration: none; }
    @media (min-width: 992px) {
      .footer-nav--list a:hover {
        text-decoration: underline; } }

.footer-copyright p {
  text-align: center;
  line-height: 1.75;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem; }

.signoff-bar {
  padding: 3rem 0;
  text-align: center; }
  @media (min-width: 992px) {
    .signoff-bar {
      padding: 1.5rem 0; } }

.signoff-btn {
  display: inline-block;
  margin: 0 0 6rem;
  height: 5rem;
  padding: 0 0 0 1.5rem;
  background-color: transparent;
  border-radius: 0;
  font-family: "U8 Black", Arial, sans-serif;
  text-decoration: none;
  color: #333333;
  line-height: 5rem;
  font-size: 16px;
  font-size: 1.6rem;
  font-size: 21px;
  font-size: 2.1rem; }
  .signoff-btn i {
    margin: 0 0 0 0.5rem;
    float: right;
    width: 5rem;
    height: 5rem;
    padding: 1.2rem; }
    .signoff-btn i svg {
      width: 2.6rem;
      height: 2.6rem;
      fill: #333333; }
  @media (min-width: 992px) {
    .signoff-btn:hover {
      background-color: transparent; } }
  .signoff-btn i {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }
  @media (min-width: 992px) {
    .signoff-btn {
      margin: 0;
      float: left; } }

.signoff-contacts--list {
  text-align: center; }
  @media (min-width: 992px) {
    .signoff-contacts--list {
      margin: 1.5rem 0 0;
      float: right; } }
  .signoff-contacts--list li {
    display: inline-block;
    margin: 0 1rem 1rem;
    font-family: "U8 Black", Arial, sans-serif; }
    @media (min-width: 992px) {
      .signoff-contacts--list li {
        margin: 0 0 0 2rem;
        float: left; } }
  .signoff-contacts--list a {
    color: #333333;
    text-decoration: none;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 1200px) {
      .signoff-contacts--list a {
        font-size: 21px;
        font-size: 2.1rem; } }
    @media (min-width: 992px) {
      .signoff-contacts--list a:hover {
        color: #000; }
        .signoff-contacts--list a:hover svg {
          fill: #000; } }
  .signoff-contacts--list i {
    margin: 0 1rem 0 0;
    float: left;
    width: 2rem;
    height: 2rem;
    padding: 0; }
    .signoff-contacts--list i svg {
      width: 2rem;
      height: 2rem;
      fill: #333333; }
    @media (min-width: 1200px) {
      .signoff-contacts--list i {
        width: 2.4rem;
        height: 2.4rem;
        padding: 0; }
        .signoff-contacts--list i svg {
          width: 2.4rem;
          height: 2.4rem;
          fill: #333333; } }

.img-banner--outer {
  position: relative; }

.img-banner {
  position: relative; }
  @media (min-width: 992px) {
    .img-banner {
      height: 60rem; } }
  .img-banner .container {
    background: url("images/text-banner-bkg.png") no-repeat center #f29204;
    background-size: auto 1000px; }
    @media (min-width: 992px) {
      .img-banner .container {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        z-index: 2;
        width: 100%;
        padding: 12rem 20px 0;
        background: none; } }
    @media (min-width: 1200px) {
      .img-banner .container {
        width: 1180px; } }

.img-banner--bkg {
  z-index: 1;
  height: 30rem;
  position: relative; }
  .img-banner--bkg:after {
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.33); }
  @media (min-width: 992px) {
    .img-banner--bkg {
      height: 60rem; } }
  .img-banner--bkg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.img-banner--content {
  padding: 3rem 0;
  text-align: center; }
  .lp .img-banner--content {
    text-align: left; }
    .lp .img-banner--content p {
      display: block; }
  @media (min-width: 992px) {
    .img-banner--content {
      padding: 0;
      text-align: left; } }
  .img-banner--content h1 {
    margin: 0 0 1.5rem;
    font-family: "U8 Black", Arial, sans-serif;
    line-height: 1.25;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 768px) {
      .img-banner--content h1 {
        font-size: 21px;
        font-size: 2.1rem; } }
    @media (min-width: 992px) {
      .img-banner--content h1 {
        font-size: 36px;
        font-size: 3.6rem; } }
    @media (min-width: 1200px) {
      .img-banner--content h1 {
        margin: 0 0 3rem;
        font-size: 50px;
        font-size: 5rem; } }
  .img-banner--content p {
    display: none;
    margin: 0 0 1rem;
    font-family: "U8 Medium", Arial, sans-serif;
    line-height: 1.75;
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 992px) {
      .img-banner--content p {
        display: block;
        font-size: 16px;
        font-size: 1.6rem; } }

@media (min-width: 1200px) {
  .img-slider .slick-dots {
    display: none !important; } }

.img-slider--controls {
  display: none;
  z-index: 5000;
  width: 100%;
  max-width: 1180px; }
  @media (min-width: 1200px) {
    .img-slider--controls {
      display: block; } }

.img-slider--prev,
.img-slider--next {
  position: absolute;
  top: 0;
  display: block; }
  .img-slider--prev i,
  .img-slider--next i {
    width: 5rem;
    height: 5rem;
    padding: 0; }
    .img-slider--prev i svg,
    .img-slider--next i svg {
      width: 5rem;
      height: 5rem;
      fill: #fff; }
  @media (min-width: 992px) {
    .img-slider--prev:hover i,
    .img-slider--next:hover i {
      width: 5rem;
      height: 5rem;
      padding: 0; }
      .img-slider--prev:hover i svg,
      .img-slider--next:hover i svg {
        width: 5rem;
        height: 5rem;
        fill: #f29204; } }

.img-slider--prev {
  left: -10rem; }
  .img-slider--prev i {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }

.img-slider--next {
  right: -10rem; }

.text-banner {
  padding: 3rem 0;
  background: url("images/text-banner-bkg.png") no-repeat center #f29204;
  background-size: auto 1000px; }
  @media (min-width: 992px) {
    .text-banner {
      padding: 12rem 0 6rem; } }
  .text-banner h1 {
    font-family: "U8 Black", Arial, sans-serif;
    line-height: 1.25;
    color: #fff;
    font-size: 30px;
    font-size: 3rem; }
    @media (min-width: 992px) {
      .text-banner h1 {
        display: block;
        padding: 0 25% 0 0; } }
    @media (min-width: 1200px) {
      .text-banner h1 {
        font-size: 36px;
        font-size: 3.6rem; } }

.text-banner--meta p {
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem; }

.text-banner h1 + .text-banner--meta {
  margin: 3rem 0 0; }

.explore-npuk {
  padding: 3rem 0 0;
  background-color: #f7f6f2; }
  @media (min-width: 1200px) {
    .explore-npuk {
      padding: 12rem 0 0; } }

@media (min-width: 768px) {
  .explore-npuk--list {
    position: relative;
    width: 100%;
    height: 40rem; } }

@media (min-width: 1200px) {
  .explore-npuk--list {
    width: 50%;
    float: left; } }

.explore-npuk--list li {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media (min-width: 768px) {
    .explore-npuk--list li {
      position: absolute; } }

.explore-npuk--list a {
  display: block;
  text-decoration: none;
  position: relative; }
  .explore-npuk--list a:after {
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75; }
  .explore-npuk--list a:hover:after {
    opacity: 0.9; }
  @media (min-width: 768px) {
    .explore-npuk--list a {
      width: 100%;
      height: 100%; } }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(1) li:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 20rem; }
    .explore-npuk--list:nth-child(1) li:nth-child(1) .explore-npuk--header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; } }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(1) li:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 20rem; }
    .explore-npuk--list:nth-child(1) li:nth-child(2) .explore-npuk--header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; } }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(1) li:nth-child(3) {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 40rem; } }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(2) li:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 40rem; }
    .explore-npuk--list:nth-child(2) li:nth-child(1) .explore-npuk--header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; } }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(2) li:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 20rem; } }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(2) li:nth-child(3) {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    height: 20rem; } }

.explore-npuk--list:nth-child(1) li:nth-child(1) a:after {
  background-color: #f29204; }

.explore-npuk--list:nth-child(1) li:nth-child(2) a:after {
  background-color: #e4042a; }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(1) li:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 20rem; }
    .explore-npuk--list:nth-child(1) li:nth-child(2) .explore-npuk--header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; } }

.explore-npuk--list:nth-child(1) li:nth-child(3) a:after {
  background-color: #ffe043; }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(1) li:nth-child(3) {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 40rem; } }

.explore-npuk--list:nth-child(2) li:nth-child(1) a:after {
  background-color: #f29204; }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(2) li:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 40rem; }
    .explore-npuk--list:nth-child(2) li:nth-child(1) .explore-npuk--header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; } }

.explore-npuk--list:nth-child(2) li:nth-child(2) a:after {
  background-color: #e4042a; }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(2) li:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 20rem; } }

.explore-npuk--list:nth-child(2) li:nth-child(3) a:after {
  background-color: #ffe043; }

@media (min-width: 768px) {
  .explore-npuk--list:nth-child(2) li:nth-child(3) {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    height: 20rem; } }

.explore-npuk--header {
  position: relative;
  z-index: 1;
  height: 10rem; }
  .explore-npuk--header p {
    left: 1rem;
    padding: 0 6rem 0 0;
    font-family: "U8 Black", Arial, sans-serif;
    color: #fff;
    text-decoration: none;
    font-size: 21px;
    font-size: 2.1rem; }
    @media (min-width: 992px) {
      .explore-npuk--header p {
        left: 2rem;
        font-size: 30px;
        font-size: 3rem; } }
  .explore-npuk--header i {
    right: 1rem;
    width: 3rem;
    height: 3rem;
    padding: 0; }
    .explore-npuk--header i svg {
      width: 3rem;
      height: 3rem;
      fill: #fff; }
    @media (min-width: 992px) {
      .explore-npuk--header i {
        right: 2rem; } }

.img-banner--outer .support-ctas {
  margin: 0 0 3rem; }
  @media (min-width: 1200px) {
    .img-banner--outer .support-ctas {
      position: absolute;
      left: 0;
      bottom: -8rem;
      z-index: 5000;
      width: 100%; } }

@media (min-width: 768px) {
  .support-cta {
    width: 33.333%;
    float: left; } }

.support-cta > a {
  display: block;
  padding: 1.5rem;
  font-family: "U8 Black", Arial, sans-serif;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .support-cta > a {
      padding: 4rem;
      font-size: 21px;
      font-size: 2.1rem; } }
  .support-cta > a i {
    margin: 2rem auto 0;
    width: 4rem;
    height: 4rem;
    padding: 0; }
    .support-cta > a i svg {
      width: 4rem;
      height: 4rem;
      fill: #fff; }

@media (min-width: 560px) {
  .support-cta.donate {
    width: 30rem;
    margin: 0 auto; } }

@media (min-width: 768px) {
  .support-cta.donate {
    width: 33.333%;
    margin: 0; } }

.support-cta.donate > a {
  background: url("images/support-cta-sprite.png") no-repeat bottom left #e58a04;
  background-size: auto 150px;
  border-radius: 5px; }
  @media (min-width: 768px) {
    .support-cta.donate > a {
      border-radius: 5px 0 0 5px; } }
  @media (min-width: 992px) {
    .support-cta.donate > a:hover {
      background-color: #333333; } }

.support-cta.social {
  position: relative;
  display: none; }
  @media (min-width: 768px) {
    .support-cta.social {
      display: block; } }
  .support-cta.social > a {
    background: url("images/support-cta-sprite.png") no-repeat center #f29204;
    background-size: auto 150px; }
    @media (min-width: 992px) {
      .support-cta.social > a:hover {
        background-color: #333333; } }
    .support-cta.social > a i {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
      transform-origin: 50%; }

.support-cta.contact {
  display: none; }
  @media (min-width: 768px) {
    .support-cta.contact {
      display: block; } }
  .support-cta.contact > a {
    background: url("images/support-cta-sprite.png") no-repeat bottom right #e58a04;
    background-size: auto 150px; }
    @media (min-width: 768px) {
      .support-cta.contact > a {
        border-radius: 0 5px 5px 0; } }
    @media (min-width: 992px) {
      .support-cta.contact > a:hover {
        background-color: #333333; } }

.sidebar .support-cta {
  display: none;
  width: 100%;
  margin: 0 0 1rem; }
  @media (min-width: 992px) {
    .sidebar .support-cta {
      display: block; } }
  .sidebar .support-cta > a {
    padding: 1.5rem;
    border-radius: 5px; }
    .sidebar .support-cta > a i {
      margin: 1rem auto 0;
      width: 3rem;
      height: 3rem;
      padding: 0; }
      .sidebar .support-cta > a i svg {
        width: 3rem;
        height: 3rem;
        fill: #fff; }

.sidebar.contact-us .support-cta.contact {
  display: none; }

.social-links {
  display: none; }
  @media (min-width: 768px) {
    .social-links {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); } }

.social-links--list {
  text-align: center; }

.social-link {
  display: inline-block;
  margin: 0 1rem; }
  .social-link a {
    display: block;
    width: 4rem;
    height: 4rem; }
    .social-link a.open {
      background-color: #333333; }
  .social-link i {
    width: 4rem;
    height: 4rem;
    padding: 0; }
    .social-link i svg {
      width: 4rem;
      height: 4rem;
      fill: #fff; }
  .social-link.twitter a {
    background-color: #2caae1; }
    @media (min-width: 992px) {
      .social-link.twitter a:hover {
        background-color: #21a6df; } }
  .social-link.facebook a {
    background-color: #3E5A99; }
    @media (min-width: 992px) {
      .social-link.facebook a:hover {
        background-color: #3a5590; } }
  .social-link.instagram a {
    background-color: #f7f6f2; }
    @media (min-width: 992px) {
      .social-link.instagram a:hover {
        background-color: #f2f1ea; } }
  .social-link.instagram i svg {
    fill: #000; }
  .social-link.youtube a {
    background-color: #ff0000; }
    @media (min-width: 992px) {
      .social-link.youtube a:hover {
        background-color: #f20000; } }

.support-cta.social:hover > a {
  background-color: #333333; }

.support-banner {
  display: none; }
  @media (min-width: 768px) {
    .support-banner {
      display: block;
      position: relative; } }
  @media (min-width: 768px) {
    .support-banner > .container {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      z-index: 2;
      width: 100%; } }
  @media (min-width: 1200px) {
    .support-banner > .container {
      width: 1180px; } }
  @media (min-width: 768px) {
    .support-banner .support-ctas {
      position: absolute;
      bottom: -2rem;
      left: 0;
      z-index: 2;
      width: 100%; } }

.support-banner--bkg {
  z-index: 1;
  width: 100%;
  height: 40rem;
  position: relative; }
  .support-banner--bkg:after {
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.33); }
  .support-banner--bkg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.support-banner--content {
  padding: 4rem 0;
  text-align: center; }
  .support-banner--content img {
    display: block;
    width: 12rem;
    height: auto;
    margin: 0 auto 3rem; }
  .support-banner--content h2 {
    color: #fff;
    font-size: 30px;
    font-size: 3rem; }

@media (min-width: 768px) {
  .support-banner + .latest-news {
    padding: 9rem 0 0; } }

.latest-news {
  padding: 3rem 0 0; }
  @media (min-width: 768px) {
    .latest-news {
      background: url("images/latest-news-bkg.jpg") no-repeat top left;
      background-size: cover; } }
  @media (min-width: 1200px) {
    .latest-news {
      padding: 6rem 0 0; } }
  .latest-news .col-md-6 {
    margin: 0 0 3rem; }
    @media (min-width: 992px) {
      .latest-news .col-md-6 {
        margin: 0; } }

.latest-news--item {
  background-color: #f7f6f2;
  border-bottom: 0.5rem solid #f29204; }

.latest-news--date {
  float: left;
  padding: 1rem;
  background-color: #f29204; }
  .latest-news--date p {
    font-family: "U8 Black", Arial, sans-serif;
    line-height: 1.25;
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem; }

.latest-news--content {
  clear: both;
  padding: 3rem; }
  .latest-news--content h3 {
    margin: 0 0 3rem;
    font-family: "U8 Black", Arial, sans-serif;
    line-height: 1.25;
    color: #333333;
    font-size: 21px;
    font-size: 2.1rem; }
    @media (min-width: 992px) {
      .latest-news--content h3 {
        height: 5rem; } }
    .latest-news--content h3 > a {
      color: inherit;
      text-decoration: none; }
      @media (min-width: 992px) {
        .latest-news--content h3 > a:hover {
          color: #f29204; } }
  .latest-news--content p {
    margin: 0 0 3rem;
    line-height: 1.75;
    color: #666666;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 992px) {
      .latest-news--content p {
        height: 10rem;
        overflow: hidden; } }
  .latest-news--content .text-btn {
    float: left;
    height: 4rem;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #f29204;
    line-height: 4rem;
    font-size: 16px;
    font-size: 1.6rem; }
    .latest-news--content .text-btn i {
      margin: 0 0 0 0.5rem;
      float: right;
      width: 4rem;
      height: 4rem;
      padding: 1rem; }
      .latest-news--content .text-btn i svg {
        width: 2rem;
        height: 2rem;
        fill: #f29204; }
    @media (min-width: 992px) {
      .latest-news--content .text-btn:hover {
        color: #e58a04; }
        .latest-news--content .text-btn:hover svg {
          fill: #e58a04; } }

.breadcrumbs {
  display: none; }
  @media (min-width: 768px) {
    .breadcrumbs {
      display: block;
      padding: 0 0 3rem; } }
  @media (min-width: 1200px) {
    .breadcrumbs {
      padding: 0 0 6rem; } }

.breadcrumbs--list span {
  color: #f29204;
  font-size: 16px;
  font-size: 1.6rem; }

.breadcrumbs--list a {
  text-decoration: none; }
  .breadcrumbs--list a span {
    color: #333333;
    text-decoration: none; }
    @media (min-width: 992px) {
      .breadcrumbs--list a span:hover {
        color: #f29204; } }

.article-intro {
  padding: 3rem 0;
  margin: 0 0 3rem;
  clear: both;
  border-bottom: 1px solid #f7f6f2; }
  .article-intro h2 {
    margin: 0 0 1.5rem;
    font-family: "U8 Black", Arial, sans-serif;
    line-height: 1.25;
    color: #333333;
    font-size: 21px;
    font-size: 2.1rem; }
    .article-intro h2 a {
      color: inherit;
      text-decoration: none; }
      @media (min-width: 992px) {
        .article-intro h2 a:hover {
          color: #f29204; } }
  .article-intro p {
    margin: 0 0 3rem;
    line-height: 1.75;
    color: #666666;
    font-size: 16px;
    font-size: 1.6rem; }
  .article-intro .text-btn {
    float: left;
    height: 4rem;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #f29204;
    line-height: 4rem;
    font-size: 16px;
    font-size: 1.6rem; }
    .article-intro .text-btn i {
      margin: 0 0 0 0.5rem;
      float: right;
      width: 4rem;
      height: 4rem;
      padding: 1rem; }
      .article-intro .text-btn i svg {
        width: 2rem;
        height: 2rem;
        fill: #f29204; }
    @media (min-width: 992px) {
      .article-intro .text-btn:hover {
        color: #e58a04; }
        .article-intro .text-btn:hover svg {
          fill: #e58a04; } }

.pagination-list {
  text-align: center; }
  .pagination-list li {
    display: inline-block;
    margin: 0 1rem;
    font-family: "U8 Black", Arial, sans-serif;
    font-size: 18px;
    font-size: 1.8rem; }
    .pagination-list li:first-child a, .pagination-list li:last-child a {
      color: #f29204; }
      @media (min-width: 992px) {
        .pagination-list li:first-child a:hover, .pagination-list li:last-child a:hover {
          color: #333333; } }
  .pagination-list span {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: #f29204;
    color: #fff;
    text-align: center;
    line-height: 4rem; }
  .pagination-list a {
    color: #333333;
    text-decoration: none; }
    @media (min-width: 992px) {
      .pagination-list a:hover {
        color: #f29204; } }

.quicklink {
  display: block;
  padding: 3rem;
  margin: 0 0 3rem;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); }
  .quicklink h2 {
    margin: 0 0 1.5rem;
    color: #333333;
    font-size: 21px;
    font-size: 2.1rem; }
  .quicklink i {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    padding: 0; }
    .quicklink i svg {
      width: 3rem;
      height: 3rem;
      fill: #f29204; }
  @media (min-width: 992px) {
    .quicklink:hover h2 {
      color: #f29204; } }

.quicklink-contacts {
  padding: 1.5rem 0 0;
  text-align: center; }
  .quicklink-contacts h3 {
    margin: 0 0 1.5rem;
    font-size: 21px;
    font-size: 2.1rem; }

.quicklink-contacts--list {
  text-align: center; }
  .quicklink-contacts--list li {
    display: inline-block;
    margin: 0 1rem 1rem; }
  .quicklink-contacts--list a {
    color: #f29204;
    text-decoration: none;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 1200px) {
      .quicklink-contacts--list a {
        font-size: 21px;
        font-size: 2.1rem; } }
    @media (min-width: 992px) {
      .quicklink-contacts--list a:hover {
        color: #000; }
        .quicklink-contacts--list a:hover svg {
          fill: #000; } }
  .quicklink-contacts--list i {
    margin: 0 1rem 0 0;
    float: left;
    width: 2rem;
    height: 2rem;
    padding: 0; }
    .quicklink-contacts--list i svg {
      width: 2rem;
      height: 2rem;
      fill: #f29204; }

.partners,
.supporters {
  text-align: center; }

.partner {
  padding: 3rem;
  margin: 0 0 3rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); }
  @media (min-width: 1200px) {
    .partner {
      padding: 3rem 6rem; } }

.partner--logo {
  position: relative;
  width: 100%;
  height: 10rem;
  margin: 0 0 3rem; }
  .partner--logo img {
    display: block;
    max-width: 100%;
    max-height: 100%; }

.partner--info h3 {
  display: block;
  margin: 0 0 1.5rem;
  font-family: "U8 Medium", Arial, sans-serif;
  color: #333333;
  line-height: 1.25;
  font-size: 21px;
  font-size: 2.1rem; }
  @media (min-width: 768px) {
    .partner--info h3 {
      height: 8rem; } }

.partner--info p {
  display: block;
  margin: 0 0 1rem;
  line-height: 1.75;
  color: #666666;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 768px) {
    .partner--info p {
      height: 20rem; } }

.partner--info a {
  color: #f29204;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 992px) {
    .partner--info a:hover {
      color: #000; } }

.supporter {
  position: relative;
  display: block;
  width: 100%;
  height: 10rem;
  margin: 0 0 3rem; }
  .supporter img {
    display: block;
    max-width: 100%;
    max-height: 100%; }

.publication {
  display: block;
  padding: 3rem;
  margin: 0 0 3rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); }
  .publication a {
    float: left;
    clear: both;
    margin: 2rem 0 0;
    height: 4rem;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #f29204;
    line-height: 4rem;
    font-size: 16px;
    font-size: 1.6rem; }
    .publication a i {
      margin: 0 0 0 0.5rem;
      float: right;
      width: 4rem;
      height: 4rem;
      padding: 1rem; }
      .publication a i svg {
        width: 2rem;
        height: 2rem;
        fill: #f29204; }
    @media (min-width: 992px) {
      .publication a:hover {
        color: #e58a04; }
        .publication a:hover svg {
          fill: #e58a04; } }
  .publication svg {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }

.publication--img {
  width: 10rem;
  margin: 0 auto 2rem; }
  @media (min-width: 768px) {
    .publication--img {
      width: 100%;
      margin: 0; } }

@media (min-width: 992px) {
  .publication--desc {
    height: 30rem; } }

.content-section--outer {
  margin: 0 0 1rem; }

.content-sections {
  display: none; }
  .content-sections.is-open {
    display: block; }

.content-section {
  padding: 0 0 3rem; }

.content-section--header + .content-sections {
  padding: 3rem 0 0; }

.content-section--header {
  position: relative;
  padding: 0 6rem 0 0;
  background-color: #f7f6f2;
  cursor: pointer;
  border-radius: 5px; }
  .content-section--header h2 {
    display: block;
    padding: 1.5rem;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 992px) {
      .content-section--header h2 {
        font-size: 21px;
        font-size: 2.1rem; } }
  .content-section--header i {
    right: 0;
    width: 4rem;
    height: 4rem;
    padding: 1.2rem; }
    .content-section--header i svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: #f29204; }
    .content-section--header i svg {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
      transform-origin: 50%; }
  .content-section--header.is-active {
    background-color: #f2f1ea; }
    .content-section--header.is-active h2 {
      color: #f29204; }
    .content-section--header.is-active svg {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
      transform-origin: 50%; }
  @media (min-width: 992px) {
    .content-section--header:hover {
      background-color: #f2f1ea; } }

.std-content h1, .std-content h2, .std-content h3, .std-content h4, .std-content h5 {
  margin: 0 0 1.5rem;
  line-height: 1.25;
  color: #333333; }
  .std-content h1 a, .std-content h2 a, .std-content h3 a, .std-content h4 a, .std-content h5 a {
    color: #f29204; }

.std-content h2 {
  font-size: 30px;
  font-size: 3rem; }

.std-content h3 {
  font-size: 21px;
  font-size: 2.1rem; }

.std-content h4 {
  font-size: 18px;
  font-size: 1.8rem; }

.std-content h5 {
  font-size: 16px;
  font-size: 1.6rem; }

.std-content p {
  margin: 0 0 2rem;
  line-height: 1.75;
  font-size: 16px;
  font-size: 1.6rem; }

.std-content ul, .std-content ol {
  margin: 0 0 3rem 2rem;
  padding: 0;
  line-height: 1.75;
  font-size: 16px;
  font-size: 1.6rem; }

.std-content p, .std-content ul, .std-content ol {
  color: #666666; }
  .std-content p a, .std-content ul a, .std-content ol a {
    color: #000;
    text-decoration: underline; }
    @media (min-width: 992px) {
      .std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
        color: #f29204; } }

.std-content strong {
  font-family: "U8 Black", Arial, sans-serif; }

.std-content ul + h2, .std-content ul + h3, .std-content ul + h4, .std-content ul + h5, .std-content ol + h2, .std-content ol + h3, .std-content ol + h4, .std-content ol + h5, .std-content img + h2, .std-content img + h3, .std-content img + h4, .std-content img + h5, .std-content .video-embed--outer + h2, .std-content .video-embed--outer + h3, .std-content .video-embed--outer + h4, .std-content .video-embed--outer + h5, .std-content p + h2, .std-content p + h3, .std-content p + h4, .std-content p + h5, .std-content blockquote + h2, .std-content blockquote + h3, .std-content blockquote + h4, .std-content blockquote + h5 {
  padding: 3rem 0 0; }

.std-content img.alignleft {
  width: 40%;
  float: left;
  margin: 0 3rem 3rem 0; }
  @media (min-width: 1200px) {
    .std-content img.alignleft {
      margin: 0 6rem 6rem 0; } }

.std-content img.alignright {
  width: 40%;
  float: right;
  margin: 0 0 3rem 3rem; }
  @media (min-width: 1200px) {
    .std-content img.alignright {
      margin: 0 0 6rem 6rem; } }

.intro-module {
  border-bottom: 1px solid #f7f6f2; }

.highlighted-list li {
  position: relative;
  display: block;
  min-height: 4rem;
  padding: 0 0 0 6rem;
  margin: 0 0 3rem; }

.highlighted-list i {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f29204;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  padding: 1rem; }
  .highlighted-list i svg {
    width: 2rem;
    height: 2rem;
    fill: #fff; }

.highlighted-list p {
  line-height: 1.75;
  color: #666666;
  font-size: 16px;
  font-size: 1.6rem; }

.video-module {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .video-module iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.downloads-list li {
  position: relative;
  margin: 0 0 3rem;
  padding: 0 0 3rem;
  border-bottom: 1px solid #f7f6f2; }
  .downloads-list li:last-child {
    margin: 0;
    padding: 0;
    border: none; }

.downloads-list a {
  position: relative;
  display: block;
  min-height: 4rem;
  padding: 0 6rem 0 0;
  text-decoration: none; }
  @media (min-width: 992px) {
    .downloads-list a:hover i {
      background-color: #333333; } }

.downloads-list i {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f29204;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  padding: 1rem; }
  .downloads-list i svg {
    width: 2rem;
    height: 2rem;
    fill: #fff; }
  .downloads-list i svg {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }

.downloads-list h3 {
  margin: 0 0 1.5rem;
  text-decoration: none;
  color: #f29204;
  font-size: 21px;
  font-size: 2.1rem; }

.downloads-list p {
  margin: 0 0 2rem;
  text-decoration: none;
  line-height: 1.75;
  color: #666666;
  font-size: 16px;
  font-size: 1.6rem; }

.download--file-size {
  display: block;
  padding: 1rem;
  float: left;
  background-color: #f7f6f2;
  border-radius: 5px;
  font-family: "U8 Black", Arial, sans-serif;
  color: #f29204;
  font-size: 14px;
  font-size: 1.4rem; }

.testimonial-module {
  padding: 0 0 3rem;
  margin: 0 0 3rem;
  border-bottom: 1px solid #f7f6f2; }
  .testimonial-module:last-child {
    padding: 0;
    margin: 0;
    border: none; }
  .testimonial-module h3 {
    text-align: center;
    font-family: "U8 Black", Arial, sans-serif;
    line-height: 1.25;
    color: #f29204;
    font-size: 21px;
    font-size: 2.1rem; }

.testimonial-module--quote {
  position: relative;
  padding: 2rem 6rem; }
  .testimonial-module--quote p {
    margin: 0 0 2rem;
    text-align: center;
    line-height: 1.75;
    color: #666666;
    font-size: 16px;
    font-size: 1.6rem; }

.open-quote,
.close-quote {
  position: absolute;
  display: block;
  width: 4rem;
  height: 4rem;
  line-height: 6rem;
  text-align: center;
  color: #f29204;
  border-radius: 100%;
  background-color: #f7f6f2;
  font-size: 36px;
  font-size: 3.6rem; }

.open-quote {
  top: 0;
  left: 0; }

.close-quote {
  bottom: 0;
  right: 0; }

.team-members {
  margin: 0 0 3rem;
  padding: 0 0 3rem;
  border-bottom: 1px solid #f7f6f2; }
  .team-members:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .team-members .row {
    padding: 0 0 1.5rem; }
    @media (min-width: 992px) {
      .team-members .row {
        padding: 0 0 3rem; } }

.team-member--img {
  width: 10rem;
  margin: 0 auto 3rem; }
  @media (min-width: 768px) {
    .team-member--img {
      width: 100%;
      margin: 0; } }

.contact-form {
  margin: 1.5rem 0;
  padding: 2rem 1rem;
  background-color: #f7f6f2; }
  @media (min-width: 1200px) {
    .contact-form {
      padding: 3rem; } }
  .contact-form h3 {
    padding: 3rem 0 0;
    margin: 3rem 0;
    color: #333333;
    border-top: 1px solid #fff;
    font-size: 21px;
    font-size: 2.1rem; }

.contact-form--row {
  margin: 0 0 3rem; }
  .contact-form--row.gdpr {
    position: relative;
    padding: 2rem;
    background-color: #ffe043; }
    .contact-form--row.gdpr label {
      margin: 0;
      line-height: 1.75;
      color: #666666;
      font-size: 16px;
      font-size: 1.6rem; }
    .contact-form--row.gdpr p {
      margin: 0; }
    .contact-form--row.gdpr a {
      color: #333333; }
  .contact-form--row p {
    margin: 0 0 1rem;
    line-height: 1.75;
    color: #666666;
    font-size: 16px;
    font-size: 1.6rem; }
  .contact-form--row p:first-of-type {
    color: #333333; }
  .contact-form--row label {
    display: block;
    margin: 0 0 1rem; }
  .contact-form--row input[type=text],
  .contact-form--row input[type=email],
  .contact-form--row input[type=tel],
  .contact-form--row input[type=password],
  .contact-form--row input[type=date] {
    width: 100%;
    height: 5rem;
    padding: 0 0.5rem;
    border: 1px solid #333333;
    color: #f29204; }
  .contact-form--row input[type=checkbox] {
    margin: 0 1rem 0 0; }
  .contact-form--row textarea {
    width: 100%;
    height: 20rem;
    padding: 0.5rem;
    border: 1px solid #333333;
    color: #f29204; }
  .contact-form--row select {
    width: 100%;
    height: 5rem;
    background-color: #fff;
    border: 1px solid #333333;
    border-radius: 0; }
  .contact-form--row input[type=submit] {
    height: 4rem;
    padding: 0 1em;
    background-color: #333333;
    border: none;
    border-radius: 5px;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #fff;
    line-height: 4rem;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 992px) {
      .contact-form--row input[type=submit] {
        height: 5rem;
        line-height: 5rem; } }
    @media (min-width: 992px) {
      .contact-form--row input[type=submit]:hover {
        background-color: #000; } }

.screen-reader-response {
  display: none; }

input[type=text].wpcf7-not-valid,
input[type=email].wpcf7-not-valid,
input[type=tel].wpcf7-not-valid,
input[type=password].wpcf7-not-valid,
textarea.wpcf7-not-valid {
  border-color: #ff0000; }

.wpcf7-not-valid-tip {
  display: block;
  padding: 1rem;
  background-color: #ff0000;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem; }

.wpcf7-response-output {
  display: block;
  padding: 2rem 1rem;
  font-family: "U8 Black", Arial, sans-serif;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem; }

.wpcf7-validation-errors {
  background-color: #ff0000; }

.wpcf7-mail-sent-ok {
  background-color: #009900; }

.reg-form {
  margin: 1.5rem 0;
  padding: 2rem 1rem;
  background-color: #f7f6f2; }
  @media (min-width: 1200px) {
    .reg-form {
      padding: 3rem; } }
  .reg-form h3 {
    padding: 3rem 0 0;
    margin: 3rem 0;
    color: #333333;
    border-top: 1px solid #fff;
    font-size: 21px;
    font-size: 2.1rem; }
  .reg-form h3:first-of-type {
    padding: 0;
    margin: 0 0 3rem;
    border: none; }

.reg-form__row {
  margin: 0 0 3rem; }
  .reg-form__row label {
    line-height: 1.75;
    color: #666666;
    font-size: 16px;
    font-size: 1.6rem; }
  .reg-form__row input[type=text],
  .reg-form__row input[type=email],
  .reg-form__row input[type=tel],
  .reg-form__row input[type=password],
  .reg-form__row input[type=date] {
    width: 100%;
    height: 5rem;
    padding: 0 0.5rem;
    border: 1px solid #333333;
    color: #f29204;
    font-size: 16px;
    font-size: 1.6rem; }
  .reg-form__row input[type=checkbox],
  .reg-form__row input[type=radio] {
    margin: 0 1rem 0 0; }
  .reg-form__row input[type=number] {
    width: 5rem;
    height: 5rem;
    padding: 0 0.5rem;
    border: 1px solid #333333;
    color: #f29204;
    font-size: 16px;
    font-size: 1.6rem; }
  .reg-form__row textarea {
    width: 100%;
    height: 20rem;
    padding: 0.5rem;
    border: 1px solid #333333;
    color: #f29204;
    font-size: 16px;
    font-size: 1.6rem; }
  .reg-form__row select {
    width: 100%;
    height: 5rem;
    background-color: #fff;
    border: 1px solid #333333;
    border-radius: 0; }
  .reg-form__row input[type=submit] {
    height: 4rem;
    padding: 0 1rem;
    background-color: #333333;
    border: none;
    border-radius: 5px;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #fff;
    line-height: 4rem;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 992px) {
      .reg-form__row input[type=submit] {
        height: 6rem;
        padding: 0 1.5rem;
        line-height: 6rem;
        font-size: 18px;
        font-size: 1.8rem; } }
    @media (min-width: 992px) {
      .reg-form__row input[type=submit]:hover {
        background-color: #000; } }

.reg-form__row-label label {
  display: block;
  margin: 0 0 1rem; }

.reg-form__row-label p:first-of-type {
  color: #333333; }

.reg-form__radio-group label,
.reg-form__checkbox-group label {
  display: block;
  margin: 0 0 1rem; }

.reg-form__grid {
  padding: 2rem 0; }

.reg-form__grid-row {
  display: flex;
  flex-wrap: wrap; }
  .reg-form__grid-row:not(:last-child) {
    padding: 0 0 2rem;
    margin: 0 0 2rem;
    border-bottom: 1px solid #666666; }

.reg-form__grid-hrow {
  display: none; }
  @media (min-width: 768px) {
    .reg-form__grid-hrow {
      display: flex;
      justify-content: flex-end; } }

.reg-form__grid-row__header {
  width: 100%;
  padding: 0 1rem 0 0; }
  @media (min-width: 768px) {
    .reg-form__grid-row__header {
      display: flex;
      width: 20%;
      padding: 1rem 2rem 0 0; } }
  @media (min-width: 1200px) {
    .reg-form__grid-row__header {
      width: 20rem; } }
  .reg-form__grid-row__header p {
    display: block;
    font-family: "U8 Black", Arial, sans-serif;
    color: #666666;
    font-size: 16px;
    font-size: 1.6rem; }

.reg-form__grid-row__label {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin: 0 0 2rem;
  height: 4rem; }
  @media (min-width: 560px) {
    .reg-form__grid-row__label {
      height: 6rem; } }
  .reg-form__grid-row__label p {
    line-height: 1.25;
    color: #666666;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__label p {
        font-size: 16px;
        font-size: 1.6rem; } }

@media (min-width: 768px) {
  .reg-form__grid-row .reg-form__grid-row__label {
    display: none; } }

.reg-form__grid-row__cells {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media (min-width: 768px) {
    .reg-form__grid-row__cells {
      width: 80%;
      flex-wrap: auto; } }
  @media (min-width: 1200px) {
    .reg-form__grid-row__cells {
      flex: 1;
      width: auto; } }

@media (min-width: 560px) {
  .reg-form__grid-row__cells.col-3 .reg-form__grid-row__cell {
    width: 33.3333333333%; } }

@media (min-width: 560px) {
  .reg-form__grid-row__cells.col-4 .reg-form__grid-row__cell {
    width: 25%; } }

@media (min-width: 560px) {
  .reg-form__grid-row__cells.col-5 .reg-form__grid-row__cell {
    width: 20%; } }

.reg-form__grid-row__cell {
  width: 25%;
  padding: 0 1rem 0 0; }
  @media (min-width: 992px) {
    .reg-form__grid-row__cell {
      padding: 0 2rem 0 0; } }

.reg-form__grid-row__price {
  height: 5rem; }
  .reg-form__grid-row__price span {
    display: block;
    padding: 1rem 0 0;
    font-family: "U8 Black", Arial, sans-serif;
    color: #f29204;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__price span {
        font-size: 16px;
        font-size: 1.6rem; } }

.reg-form__grid-row__sub-total {
  display: flex;
  padding: 1rem 0 0; }
  .reg-form__grid-row__sub-total span {
    font-family: "U8 Black", Arial, sans-serif;
    color: #f29204;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__sub-total span {
        font-size: 16px;
        font-size: 1.6rem; } }
  .reg-form__grid-row__sub-total input {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: #f29204;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__sub-total input {
        font-size: 16px;
        font-size: 1.6rem; } }

.reg-form__grid-row__qty {
  display: flex;
  justify-content: flex-start;
  height: 5rem; }
  .reg-form__grid-row__qty label {
    margin: 1rem 1rem 0 0;
    font-family: "U8 Black", Arial, sans-serif;
    color: #666666;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__qty label {
        font-size: 16px;
        font-size: 1.6rem; } }
  .reg-form__grid-row__qty input {
    width: 5rem;
    height: 5rem;
    padding: 0 0.5rem;
    border: 1px solid #333333;
    color: #f29204;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__qty input {
        font-size: 16px;
        font-size: 1.6rem; } }
  .reg-form__grid-row__qty select {
    width: 6rem;
    height: 5rem;
    background-color: #fff;
    border: 1px solid #333333;
    color: #f29204;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 560px) {
      .reg-form__grid-row__qty select {
        font-size: 16px;
        font-size: 1.6rem; } }

.reg-form__inline-no {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .reg-form__inline-no label {
    margin: 0 2rem 0 0;
    line-height: 1.75;
    color: #666666;
    font-size: 16px;
    font-size: 1.6rem; }
  .reg-form__inline-no input {
    width: 5rem;
    height: 5rem;
    padding: 0 0.5rem;
    border: 1px solid #333333;
    color: #f29204;
    font-size: 16px;
    font-size: 1.6rem; }

.reg-form__payment-options {
  padding: 2rem;
  margin: 0 0 3rem;
  background-color: #f29204; }
  @media (min-width: 992px) {
    .reg-form__payment-options {
      padding: 3rem; } }

.reg-form__payment-option {
  color: #fff; }
  .reg-form__payment-option:not(:last-child) {
    margin: 0 0 2rem; }
  .reg-form__payment-option h4 {
    margin: 0 0 0.5rem;
    font-size: 18px;
    font-size: 1.8rem; }
  .reg-form__payment-option p {
    line-height: 1.75;
    font-size: 16px;
    font-size: 1.6rem; }
  .reg-form__payment-option a {
    color: inherit;
    text-decoration: underline; }

.reg-form__total {
  padding: 2rem;
  margin: 1rem 0 0;
  background-color: #333333; }
  @media (min-width: 992px) {
    .reg-form__total {
      padding: 3rem; } }
  .reg-form__total h4,
  .reg-form__total span {
    font-family: "U8 Black", Arial, sans-serif;
    color: #fff; }
  .reg-form__total h4 {
    margin: 0 0 1rem;
    font-size: 18px;
    font-size: 1.8rem; }
  .reg-form__total span {
    display: flex;
    font-size: 36px;
    font-size: 3.6rem; }
  .reg-form__total input {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    font-family: "U8 Black", Arial, sans-serif;
    color: #fff;
    font-size: 36px;
    font-size: 3.6rem; }

.cf7-repeater-container {
  margin: 0 0 3rem; }

.cf7-button-repeater,
.cf7-remove-repeater-container {
  margin: 0; }
  .cf7-button-repeater a,
  .cf7-remove-repeater-container a {
    display: inline-block;
    background: #333333 !important;
    color: #fff !important;
    padding: 0 1rem !important;
    text-decoration: none;
    height: 4rem;
    border: none;
    border-radius: 5px;
    font-family: "U8 Black", Arial, sans-serif;
    text-decoration: none;
    color: #fff;
    line-height: 4rem;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 992px) {
      .cf7-button-repeater a,
      .cf7-remove-repeater-container a {
        height: 5rem;
        line-height: 5rem; } }
    @media (min-width: 992px) {
      .cf7-button-repeater a:hover,
      .cf7-remove-repeater-container a:hover {
        background-color: #000; } }

input.ctf7-total {
  text-align: left !important; }

.sub-nav--outer.cats {
  display: none; }
  @media (min-width: 992px) {
    .sub-nav--outer.cats {
      display: block; } }

.sub-nav--outer + .support-ctas--list {
  margin: 3rem 0 0; }

.toggle--sub-nav {
  display: block;
  height: 5rem;
  padding: 0 0 0 1.5rem;
  background-color: #f7f6f2;
  border-radius: 5px;
  font-family: "U8 Black", Arial, sans-serif;
  text-decoration: none;
  color: #333333;
  line-height: 5rem;
  font-size: 16px;
  font-size: 1.6rem; }
  .toggle--sub-nav i {
    margin: 0 0 0 0.5rem;
    float: right;
    width: 5rem;
    height: 5rem;
    padding: 1.2rem; }
    .toggle--sub-nav i svg {
      width: 2.6rem;
      height: 2.6rem;
      fill: #333333; }
  @media (min-width: 992px) {
    .toggle--sub-nav:hover {
      background-color: #f2f1ea; } }
  .toggle--sub-nav i {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%; }
  .toggle--sub-nav.open {
    background-color: #e58a04;
    color: #fff; }
    .toggle--sub-nav.open i {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
      transform-origin: 50%; }
    .toggle--sub-nav.open svg {
      fill: #fff; }
  @media (min-width: 768px) {
    .toggle--sub-nav {
      display: none; } }

.sub-nav {
  display: none;
  margin: 1rem 0 0;
  background-color: #f7f6f2;
  border-radius: 5px; }
  @media (min-width: 768px) {
    .sub-nav {
      display: block !important;
      margin: 0; } }

.sub-nav--list a {
  display: block;
  padding: 1.5rem 1rem;
  color: #333333;
  text-decoration: none;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 992px) {
    .sub-nav--list a:hover {
      color: #f29204; } }

.contact-info {
  margin: 0 0 1.5rem; }
  .contact-info h2 {
    margin: 0 0 1rem;
    font-size: 18px;
    font-size: 1.8rem; }
  .contact-info h3 {
    margin: 0 0 1rem;
    font-size: 16px;
    font-size: 1.6rem; }

.donations-modal--overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.75); }

.close--donations-modal {
  position: absolute;
  top: 0;
  right: 0; }
  .close--donations-modal i {
    width: 4rem;
    height: 4rem;
    padding: 1.2rem; }
    .close--donations-modal i svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: #666666; }
  @media (min-width: 992px) {
    .close--donations-modal:hover svg {
      fill: #e4042a; } }

.donations-modal {
  display: block;
  width: 90%;
  padding: 6rem;
  border-radius: 5px;
  background-color: #fff; }
  @media (min-width: 560px) {
    .donations-modal {
      width: 75%; } }
  @media (min-width: 992px) {
    .donations-modal {
      width: 50%;
      padding: 9rem 6rem; } }
  @media (min-width: 1200px) {
    .donations-modal {
      max-width: 50rem; } }

.donations-modal--header {
  margin: 0 0 3rem;
  text-align: center; }
  @media (min-width: 768px) {
    .donations-modal--header {
      margin: 0 0 6rem; } }
  .donations-modal--header h2 {
    margin: 0 0 1rem;
    font-size: 21px;
    font-size: 2.1rem; }
    @media (min-width: 768px) {
      .donations-modal--header h2 {
        margin: 0 0 3rem;
        font-size: 30px;
        font-size: 3rem; } }
  .donations-modal--header p {
    font-size: 16px;
    font-size: 1.6rem; }

.donations-modal--logo {
  display: block;
  width: 100%;
  padding: 1rem 25%;
  border: 1px solid transparent; }
  @media (min-width: 768px) {
    .donations-modal--logo {
      padding: 3rem 20%; } }
  @media (min-width: 992px) {
    .donations-modal--logo:hover {
      border: 1px solid #ffe043;
      border-radius: 5px; } }

/*# sourceMappingURL=style.css.map */